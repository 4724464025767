<template>
  <div>
    <div class="row" v-show="false">
      <div class="col-md-12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            <b>Data tables</b> The <code>v-data-table</code> component is used
            for displaying tabular data. Features include sorting, searching,
            pagination, inline-editing, header tooltips, and row selection.
            <a
              class="font-weight-bold"
              href="https://vuetifyjs.com/en/components/data-tables"
              target="_blank"
            >
              See documentation.
            </a>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-card>
          <v-card-title>
            {{ $t("PAGES.MEMBER_GROUPS.NEW.TITLE") }}
          </v-card-title>
          <b-card-body>
            <v-card-text>
              <v-form
                ref="new_member_group_form"
                v-model="valid"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="memberGroup.group_name"
                        :rules="titleRules"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.MEMBER_GROUPS.ATTRIBUTES.TITLE')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="memberGroup.is_admin"
                        :label="$t('PAGES.MEMBER_GROUPS.ATTRIBUTES.ADMIN')"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="memberGroup.is_banned"
                        :label="$t('PAGES.MEMBER_GROUPS.ATTRIBUTES.BANNED')"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="memberGroup.delete_users"
                        :label="
                          $t('PAGES.MEMBER_GROUPS.ATTRIBUTES.DELETE_USERS')
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="memberGroup.allow_import"
                        :label="
                          $t('PAGES.MEMBER_GROUPS.DIALOG.MANAGE.ALLOW_IMPORT')
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-checkbox
                        v-model="memberGroup.allow_export"
                        :label="
                          $t('PAGES.MEMBER_GROUPS.DIALOG.MANAGE.ALLOW_EXPORT')
                        "
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea
                        clearable
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :label="$t('PAGES.MEMBER_GROUPS.ATTRIBUTES.NOTE')"
                        v-model="memberGroup.notes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-btn color="success" class="mr-4" @click="validate">
                        {{ $t("COMMON.BUTTON.SAVE") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <div style="flex: 1 1 auto"></div>
          </b-card-body>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { CREATE_MEMBER_GROUP_DATA } from "@/core/services/store/member_groups.module";
import Swal from "sweetalert2";

export default {
  name: "NewMemberGroupForm",
  data() {
    return {
      titleRules: [
        (v) =>
          (v && v.length > 4) ||
          this.$t("PAGES.MEMBER_GROUPS.ATTRIBUTES.MIN_LENGHT_TITLE"),
      ],
      requiredRules: [(v) => !!v || this.$t("COMMON.ERRORS.REQUIRED")],
      show1: false,
      show2: false,
      memberGroup: {
        group_name: "",
        is_admin: false,
        is_banned: false,
        delete_users: false,
        allow_import: false,
        allow_export: false,
        notes: ''
      },
      valid: true,
    };
  },
  computed: {
    ...mapState({
      // errors: state => state.calling.errors()
      errors: (state) => state.member_groups.errors,
    }),
    ...mapGetters(["memberGroupsList", "regUsersList"]),
    dataMemberGroupList() {
      return this.memberGroupsList;
    },
  },
  components: {},
  mounted() {
    (this.errors = {}),
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("PAGES.MEMBER_GROUPS.AREA") },
        { title: this.$t("PAGES.MEMBER_GROUPS.NEW.TITLE") },
      ]);
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate() {
      if (this.$refs.new_member_group_form.validate()) {
        this.snackbar = true;
        this.save();
      }
    },
    save() {
      this.save_status = this.$store.dispatch(
        CREATE_MEMBER_GROUP_DATA,
        this.memberGroup
      );
      if(!this.error){
        Swal.fire({
          title: this.$t("COMMON.SAVING_SUCCESS"),
          text: '',
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.memberGroup = {};
      }
    },
    showErrorSwal(message) {
      Swal.fire({
        title: "",
        text: message,
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    },
  },
  watch: {
    errors: function (val) {
      if (val) {
        this.showErrorSwal(this.$t("COMMON.ERRORS.REQUEST.GENERIC"));
      }
    },
  },
};
</script>
